import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H3Text, P3Text } from '../Typography/styled';
import { web3Colors } from '../Web3Page/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { FooterContainer, FooterWrapper } from './styled';
import { ClutchButtons } from './ClutchButtons';

import Facebook from '../../assets/icons/updateDesign/facebook.svg';
import Linkedin from '../../assets/icons/updateDesign/linkedin.svg';
import Twitter from '../../assets/icons/updateDesign/twitter.svg';
import Instagram from '../../assets/icons/updateDesign/instagram.svg';
import { MOBILE_VIEW_BREAKPOINT } from '../Web3Page/constants';

const socialList = [
  {
    name: 'Twitter',
    link: 'https://twitter.com/dashbouquet',
    tag: <Twitter />,
    web3Tag: <Twitter fill="white" />,
  },
  {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/company/dash-bouquet/',
    tag: <Linkedin />,
    web3Tag: <Linkedin fill="white" />,
  },
  {
    name: 'Instagram',
    link: 'https://instagram.com/dashbouquet?igshid=YmMyMTA2M2Y=',
    tag: <Instagram />,
    web3Tag: <Instagram fill="white" />,
  },
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/dashbouquet/',
    tag: <Facebook />,
    web3Tag: <Facebook fill="white" />,
  },
];

export const FooterContent = styled.div`
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 40px;
  @media ${breakpoints.mds} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.sm} {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`;
export const FooterContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  & > a {
    -webkit-user-select: text;
    -webkit-touch-callout: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  @media ${breakpoints.mds} {
    gap: 20px;
  }
`;

const Light = styled.div`
  z-index: 0;
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  bottom: 95px;
  width: 50%;
  height: 200px;
  background-image: radial-gradient(
    ellipse at bottom,
    rgb(116 98 244 / 24%) -20%,
    rgba(255, 255, 0, 0) 80%
  );
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    width: 100%;
  }
`;

const Footer = ({ web3Mode = false }) => {
  const currentYear = new Date().getFullYear();
  const web3ModeAvailible = web3Mode;
  const { downArrowIcon, mainLogo, ...data } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            event
            subLinks {
              name
              link
              event
            }
          }
        }
      }
    }
  `);
  const menuList = data.site.siteMetadata.menuLinks;

  const servicesList = menuList.filter((el) => el.name === 'Services')[0].subLinks;
  const solutionList = menuList.filter((el) => el.name === 'Solutions')[0].subLinks;
  const companyList = [
    ...menuList.filter(
      (el) => el.name !== 'Services' && el.name !== 'Solutions' && el.name !== 'Company'
    ),
    ...menuList.filter((el) => el.name === 'Company')[0].subLinks,
  ];

  return (
    <FooterWrapper
      className="footer"
      as="footer"
      id="contacts"
      web3ModeAvailible={web3ModeAvailible}
    >
      <FooterContent>
        <FooterContentBlock>
          <H3Text color={web3ModeAvailible ? colors2.white : colors2.black}>Email</H3Text>
          <a href="mailto:contact@dashbouquet.com">
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              contact@dashbouquet.com
            </P3Text>
          </a>
        </FooterContentBlock>
        <FooterContentBlock>
          <H3Text color={web3ModeAvailible ? colors2.white : colors2.black}>Phone numbers</H3Text>
          <a href="tel:+16469348662">
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              +1 646 934 8662 (US)
            </P3Text>
          </a>
          <a href="tel:+442045770721">
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              +44 2045 770721 (GB)
            </P3Text>
          </a>
        </FooterContentBlock>
        <FooterContentBlock>
          <H3Text color={web3ModeAvailible ? colors2.white : colors2.black}>Find us</H3Text>
          <a
            href="http://maps.google.com/?q=Estonia, Tallinn city, Harju county, 10117, Narva mnt 7-353"
            target="_blank"
            rel="noreferrer"
          >
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              Estonia, Tallinn city,
            </P3Text>
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              Harju county, 10117, Narva mnt 7-353
            </P3Text>
          </a>
          <a
            href="https://www.google.com/maps/place/Domaniewska+17%2F19%2F133,+02-672+Warszawa,+%D0%9F%D0%BE%D0%BB%D1%8C%D1%88%D0%B0/@52.179425,21.0704822,11z/data=!4m6!3m5!1s0x471932d0c2f00001:0xf3b91d9e38fadea5!8m2!3d52.183079!4d21.0160796!16s%2Fg%2F11vbb0b7wv?entry=ttu"
            target="_blank"
            rel="noreferrer"
          >
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              Poland, Warszawa,
            </P3Text>
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              ul. Domaniewska, nr 17/19, lok. 133 02-672.
            </P3Text>
          </a>
          <FooterContainer className="mobile--dispalyed">
            {socialList.map((item) => {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  key={`social-icon_${item.name}`}
                  css={`
                    margin-right: 30px;
                  `}
                  className="social__icon"
                >
                  {web3ModeAvailible ? item.web3Tag : item.tag}
                </a>
              );
            })}
          </FooterContainer>
        </FooterContentBlock>
      </FooterContent>
      {!web3ModeAvailible ? (
        <FooterContent>
          <FooterContentBlock>
            <H3Text color={web3ModeAvailible ? colors2.white : colors2.black}>Services</H3Text>
            <Flex flexDirection="column" style={{ gap: '20px' }}>
              {servicesList.map((el) => {
                return (
                  <Link to={el.link}>
                    <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
                      {el.name}
                    </P3Text>
                  </Link>
                );
              })}
            </Flex>
          </FooterContentBlock>
          <FooterContentBlock>
            <H3Text color={web3ModeAvailible ? colors2.white : colors2.black}>Solutions</H3Text>
            <Flex flexDirection="column" style={{ gap: '20px' }}>
              {solutionList.map((el) => {
                return (
                  <Link to={el.link}>
                    <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
                      {el.name}
                    </P3Text>
                  </Link>
                );
              })}
            </Flex>
          </FooterContentBlock>
          <FooterContentBlock>
            <H3Text color={web3ModeAvailible ? colors2.white : colors2.black}>Company</H3Text>
            <Flex flexDirection="column" style={{ gap: '20px' }}>
              {companyList.map((el) => {
                return (
                  <Link to={el.link}>
                    <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
                      {el.name}
                    </P3Text>
                  </Link>
                );
              })}
            </Flex>
          </FooterContentBlock>
        </FooterContent>
      ) : null}
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        style={{ zIndex: 1 }}
      >
        <Flex justifyContent="space-between" className="footer_block__bottom">
          <Flex className="footer_block__text">
            <Flex>
              <Link to="/privacy-policy">
                <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
                  Privacy Policy
                </P3Text>
              </Link>
            </Flex>
            <P3Text color={web3ModeAvailible ? web3Colors.grey : colors2.lightText}>
              © {currentYear} Dashbouquet Development LLC. All Rights Reserved.
            </P3Text>
          </Flex>

          <FooterContainer className="desktop--dispalyed">
            {socialList.map((item) => {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  key={`social-icon_${item.name}`}
                  css={`
                    margin-right: 30px;
                  `}
                  className="social__icon"
                >
                  {web3ModeAvailible ? item.web3Tag : item.tag}
                </a>
              );
            })}
          </FooterContainer>
        </Flex>
      </Flex>
      {web3ModeAvailible && <Light />}
      <ClutchButtons />
    </FooterWrapper>
  );
};

export default Footer;
